// Clients.js
import React from 'react';
import { linksClass, h1, h2, h3, featureImageClass, imageClass } from '../styles/siteClasses';


function Clients() {
    return (
        <section id="clients">
            <div className='text-white'>
                <div className='pb-8'>
                    <h1 className={h1}>
                        Valued Collaborations
                    </h1>
                    <h3 className={h3}>
                        QuarkStar LLC
                    </h3>
                    <p className=" mb-4">
                        QuarkStar is an emerging player in the solid-state lighting sector, known for its award-winning <a href='/articles/quarkstar-edge-x-technology' className={linksClass}>Edge-X technology</a> and extensive patent portfolio, encompassing innovations in waveguide optics, LED applications, and advanced controls
                    </p>
                    <p className="text-xs mb-4">
                        2016-present
                    </p>
                </div>

                <h3 className={h3}>
                    Gaia Photonics Inc.
                </h3>
                <p className=" mb-4">
                    Founded in 2008 by Ingo Speier, Gaia Photonics specializes in Solid State Lighting (SSL) services, including product design and development, characterization, and business consulting.
                </p>
                <p className="text-xs mb-4">
                    2019-present
                </p>
            </div>
        </section>
    );
}

export default Clients;
