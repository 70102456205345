// src/components/BackButton.js
import React from 'react';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1);
    };

    return (
        <button
            onClick={handleBackClick}
            className='mb-6 text-yellow-400 hover:text-yellow-500 focus:outline-none'
            aria-label="Go back"
        >
            <div className='flex items-center text-xl font-extralight'>
                <ChevronLeftIcon className="w-6 h-6 mr-2" />
                Back
            </div>
        </button>
    );
};

export default BackButton;