// Consulting.jsnp
import React from 'react';
import { linksClass, h1, h2, h3, featureImageClass, imageClass } from '../styles/siteClasses';
import { photoCreditClass, bulletPointClass } from '../styles/articleClasses';
import { CheckIcon } from '@heroicons/react/20/solid';

function Consulting() {
    return (
        <section id="consulting">

            <h1 className={h1}>
                Expertise You Can Trust
            </h1>

            <p className=" text-white mb-10">
                With a solid foundation in physics and years of hands-on experience in engineering and characterization, I offer specialized consulting services designed to elevate your projects to the next level. Whether you're an innovator in tech or a company looking to integrate advanced solutions, my goal is to provide you with the technical insights and strategic guidance needed to achieve your vision.
            </p>

            <h2 className={h2}>
                Services Offered
            </h2>
            <p className=" text-white mb-10">
                <ul>
                    <CheckItem>
                        <strong>
                            Engineering & Characterization:
                        </strong>
                        <p>
                            Leveraging my deep understanding of physics and engineering to solve complex problems, from data analysis to experimental design and validation.
                        </p>

                    </CheckItem>
                    <CheckItem>
                        <strong>
                            Technology & Prototype Integration:
                        </strong>
                        <p>
                            Providing expertise in the integration of advanced technologies and prototype development, including sensor integration and data handling, to create seamless and efficient systems tailored to your project needs.
                        </p>
                    </CheckItem>
                    <CheckItem>
                        <strong>
                            Data Handling & Analysis:
                        </strong>
                        <p>
                            Offering comprehensive services in data management, analysis, and automation to enhance research efforts and drive informed decision-making.
                        </p>
                    </CheckItem>
                </ul>
            </p>
            <h2 className={h2}>
                Why Choose Me?
            </h2>


            <p className=" text-white mb-10">
                With a proven track record of success in the technology sector, I bring a unique blend of technical expertise and creative problem-solving to every project. My hands-on experience with groundbreaking technologies means I understand the challenges and opportunities that come with developing modern systems.
            </p>


            <h2 className={h2}>
                Let's Work Together

            </h2>

            <p className=" text-white mb-4">

                If you're ready to take your project to the next level, I'd love to hear from you. <a href='mailto:hello@ronniejohnson.ca' className={linksClass}> Contact me </a> today to discuss how my consulting services can benefit your business.
            </p>

        </section>
    );
}

function CheckItem({ children }) {
    return (
        <li className="flex items-start mb-6">
            <CheckIcon className="w-5 h-5 mr-2 flex-shrink-0" />
            <span className='leading-snug'>{children}</span>
        </li>
    );
}

export default Consulting;
