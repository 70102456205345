// UnderConstruction.jsx

import React from 'react';
import { linksClass, h1, h2, h3 } from '../styles/siteClasses';



function UnderConstruction() {
    return (
        <section id="UnderConstruction">
            <div className="text-white mb-4">
                <h1 className={h1}>
                    🚧 
                </h1>
                <h1 className={h1}>
                    Page under construction.
                </h1>
                <h1 className={h1}>
                    Check back soon!
                </h1>
            </div>
        </section>
    );
}

export default UnderConstruction;