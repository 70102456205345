// src/pages/articles/mfah-luminaire-installation.js
import React from 'react';
import { linksClass, h1, h2, featureImageClass, imageClass } from '../../styles/siteClasses';
import { photoCreditClass, bulletPointClass } from '../../styles/articleClasses';
import '../../styles/article.css'; // Ensure the path is correct
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import BackButton from '../../components/BackButton'; // Import the BackButton component



const LuminaireInstallation = () => {

    return (
        <section className="prose mx-auto text-white">
            
            <BackButton />

            <h1 className={h1}>
                Luminaire Installation at Nancy and Rich Kinder Building, The Museum of Fine Arts, Houston
            </h1>

            <img
                src="/images/photos/mfha.jpg"
                alt="Aerial view of Nancy and Rich Kinder Building, The Museum of Fine Arts, Houston"
                className={featureImageClass}
            />

            <p className={photoCreditClass}>
                Photo — <a href='https://www.petermolick.com' className={linksClass}>Peter Molick</a> / Thomas Kirk III
            </p>

            <p className="mb-4">
                <span className="text-3xl">A</span>s a member of the QuarkStar engineering team, I was deeply involved in the research, development, and manufacturing of wall-washing luminaires for the Nancy and Rich Kinder Building at The Museum of Fine Arts, Houston (MFAH). This $450 million campus expansion project included one of the largest lighting installations in North America in 2020.
            </p>

            <h2 className={h2}>Phase 1.  Research and Development of Q-Wall Luminaire</h2>
            <p className="mb-4">
                The development of the Q-Wall luminaire, utilizing Edge-X technology, was a multi-year process. We progressed from functional prototypes to a fully UL-certified product.
            </p>
            <p className="mb-4">
                The project involved refining the design, conducting characterization and mechanical/electrical validations, performing thermal analysis, and developing intricate assembly methods for sub-components, including the precise bonding of optical parts.
            </p>

            <img
                src="/images/photos/mfha-gallery.jpg"
                alt="Aerial view of Nancy and Rich Kinder Building, The Museum of Fine Arts, Houston"
                className={featureImageClass}
            />
            <p className={photoCreditClass}>
                The Museum of Fine Arts, Houston sought diffuse, uniform lighting that could be tuned to match natural daylight. The Q-Wall luminaire achieved this with dual 3000K and 5000K LED channels, perfectly mixed between each LED using QuarkStar Edge-X reflective waveguide technology. The slim design of the luminaires allowed for discreet installation within ceiling coves.
                Photo — <a href='http://www.richardbarnes.net' className={linksClass}>Richard Barnes</a>
            </p>


            <h2 className={h2}>Phase 2. Manufacturing and Installation</h2>
            <p className="mb-4">
                Manufacturing commenced in 2019, producing over 1,500 linear feet of luminaires. This required retrofitting our lab and shop space into a UL-certified assembly floor, training staff, and implementing stringent quality control processes.
            </p>
            <p className="mb-4">
                In the months leading up to production, we collaborated with our manufacturing partners to produce and bond the injected optical components, a process that involved extensive testing and ultimately required the precision of a robotic arm in manufacturing.
            </p>

            <div className='flex flex-row w-full justify-between'>
                <img
                    src="/images/photos/qwall-Full_100_800_5463-NoWire.jpg"
                    alt="Customized Q-Wall luminaire featuring anti-glare louvers, as implemented at the Museum of Fine Arts, Houston"
                    className={`${imageClass} w-[49%] object-cover`} // Slightly reduce width
                />
                <img
                    src="/images/photos/qwall-Full_100_800_6488-HDR-NoWire.jpg"
                    alt="The standard Q-Wall model."
                    className={`${imageClass} w-[49%] object-cover`} // Slightly reduce width
                />
            </div>

            <p className={photoCreditClass}>
                Left: The customized Q-Wall luminaire featuring anti-glare louvers, as implemented at the Museum of Fine Arts, Ho
                uston. Right: The standard Q-Wall model. Photos – Ronnie Johnson
            </p>

            <img
                src="/images/photos/upt-quarkstar-edge-x.webp"
                alt="QuarkStar Edge-X Q-Wall optics"
                className={featureImageClass}
            />

            <p className={photoCreditClass}>
                Close-up of Q-Wall optics using Edge-X technology. Photo – <a href='https://www.wittmann-group.com/en/report-upt-optik-wodak-gmbh' className={linksClass} > WITTMANN UPT-Optik Wodak GmbH</a>
            </p>

            <ul className="custom-list list-disc pl-5"> {/* Add padding to align the list */}
                <li className="mb-4 flex items-start"> 
                    <InformationCircleIcon className={bulletPointClass} />
                    <span className="flex-1"> 
                        <a href="https://upt-optik.de/" className={linksClass}>
                            UPT Optik Wodak GmbH
                        </a>, a precision injection optics molder based in Germany, played a critical role in developing the complex optics and bonding processes essential to the performance of the Q-Wall luminaire.
                    </span>
                </li>
            </ul>
            
            <p className="mb-4">
                The project had an accelerated assembly schedule of just over two months, following ten months of meticulous planning.
            </p>

            <p className="mb-4">
                Key members of the engineering team who contributed to the success of this project include <a href="https://www.linkedin.com/in/ingo-speier-a6a93623/" className={linksClass}>Ingo Speier</a>, Andrew Gladwin, myself, and offsite contributions by <a href="https://www.linkedin.com/in/ebretschneider/" className={linksClass}>Eric Bretschneider</a>, <a href="https://www.linkedin.com/in/robtcgardner/" className={linksClass}>Bob Gardner</a>, and others. <a href="https://lobsintl.com/" className={linksClass}>L'Observatoire International</a>, a renowned New York City lighting design firm, specified the MFAH gallery lighting using QuarkStar's Q-Wall luminaire. <span className="text-yellow-400 text-xl relative" style={{ top: '0.15em' }}>■</span>

            </p>

            <h3 className={h1}>
                Press:
            </h3>
        </section>
    );
};

export default LuminaireInstallation;