// src/pages/articles/optics-of-sylvania-lighting.js
import React from 'react';
import { linksClass, h1, h2, featureImageClass, imageClass } from '../../styles/siteClasses';
import { photoCreditClass, bulletPointClass} from '../../styles/articleClasses';
import '../../styles/article.css'; // Ensure the path is correct
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import BackButton from '../../components/BackButton'; // Import the BackButton component



const OpticsSylvania = () => {

    return (
        <section className="prose mx-auto text-white">
            
            <BackButton />

            <h1 className={h1}>
                Optics of Sylvania Lighting Concord Equinox
            </h1>
            <img
                src="/images/photos/EQUINOX-fixture-red-dot.webp"
                alt="Sylvania Equinox fixture product image"
                className={featureImageClass}
            />
            <p className="mb-4">
            <span className="text-3xl">I</span>n 2023, <a href="https://www.sylvania-lighting.com/en-int" className={linksClass}>Feilo Sylvania</a> introduced the <a href=" https://www.sylvania-lighting.com/product/en-int/category/interior-lighting/downlights/families/equinox-f/" className={linksClass}> Equinox recessed downlight fixture </a> as part of <a href="https://www.sylvania-lighting.com/en-int/concord" className={linksClass}>Sylvania’s Concord</a> line. This fixture, incorporating QuarkStar’s Edge-X waveguide technology licensed to Feilo Sylvania, features a unique combination of downlighting and uplighting capabilities. As a key member of the QuarkStar engineering team, I played a crucial role in developing and validating the advanced optics that enable this distinctive lighting feature. The Equinox downlight has been widely recognized, earning the <a href="https://www.red-dot.org/project/concord-equinox-61600" className={linksClass}>2023 Red Dot Design Award for outstanding lighting design</a>. The fixture is available across Europe, the Middle East, and Africa (EMEA).
            </p>

            <div className='flex flex-row w-full justify-between'>
                <img
                    src="/images/photos/equinox-installation.jpg"
                    alt="Sylvania Equinox fixture installation application image"
                    className={`${imageClass} w-[49%] object-cover`} // Slightly reduce width
                />
                <img
                    src="/images/photos/quarkstar-downlight-app.jpg"
                    alt="QuarkStar circular downlight prototype application image"
                    className={`${imageClass} w-[49%] object-cover`} // Slightly reduce width
                />
            </div>


            <p className={photoCreditClass}>
                Left: Installation of Sylvania’s Equinox fixture at BTB GES in Paris, France. Right: Demonstration of Edge-X circular downlight technology at Light + Building 2018 in Frankfurt, Germany. Photos – Sylvania, QuarkStar
            </p>

            <img
                src="/images/photos/quarkstar-downlight-prototype.jpg"
                alt="QuarkStar circular downlight prototype application image"
                className={featureImageClass}
            />

            <p className={photoCreditClass}>
                Prototype of Edge-X circular downlight technology. Photo — Ronnie Johnson
            </p>

            <p className="mb-4">
                Key members of the engineering team who contributed to the success of this project include <a href="https://www.linkedin.com/in/ingo-speier-a6a93623/" className={linksClass}>Ingo Speier</a>, Andrew Gladwin, <a href="https://www.linkedin.com/in/ebretschneider/" className={linksClass}>Eric Bretschneider</a>, myself, and others. <span className="text-yellow-400 text-xl relative" style={{ top: '0.15em' }}>■</span>
            </p>

            <h3 className={h1}>
                Press:
            </h3>

        </section>
    );
};

export default OpticsSylvania;