// Photography.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import { XMarkIcon } from '@heroicons/react/16/solid';


function Gallery() {
    const location = useLocation();

    // Hide the gallery if not on the gallery route
    if (location.pathname !== '/photography') {
        return null;
    }

    return (
        <div className="fixed inset-0 bg-black flex flex-col items-center justify-center z-50 overflow-auto">
            <button
                onClick={() => window.history.back()}
                className="absolute top-4 right-4 bg-gray-800 text-white p-4 rounded-full shadow-lg"
                aria-label="Close Gallery"
            >
                <XMarkIcon className="w-6 h-6" />
            </button>
            <h1 className="text-4xl text-white mb-8">Photography Gallery</h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4">
                {/* Replace with your actual photo gallery */}
                <div className="bg-gray-200 h-48 flex items-center justify-center">Photo 1</div>
                <div className="bg-gray-200 h-48 flex items-center justify-center">Photo 2</div>
                <div className="bg-gray-200 h-48 flex items-center justify-center">Photo 3</div>
                {/* Add more photo placeholders or actual images */}
            </div>
        </div>
    );
}

export default Gallery;
