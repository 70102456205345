// Contact.js
import React from 'react';
import { linksClass } from '../styles/siteClasses';

function Contact() {
    return (
        <section id="contact">
            <div className='text-white'>
                <p className="text-4xl mt-16 mb-8">
                    Let's get in Touch
                </p>
                <p className="text-lg mb-4">
                    Get in touch to discuss how I can help you with your next project.
                </p>
                <p className="text-lg mb-4">
                    <a href='mailto:hello@ronniejohnson.ca' className= { linksClass }>
                        hello@ronniejohnson.ca
                        </a>
                </p>
            </div>
        </section>
    );
}

export default Contact;
