// Bio.js
import React from 'react';
import { linksClass } from '../styles/siteClasses';


function Bio() {
    return (
        <section id="bio">
            <p className="text-white text-lg mb-4">
                I specialize in helping companies realize their technology objectives. With a BSc in Physics and over eight years of experience in engineering, characterization, and data analysis, I offer versatile problem-solving skills to transform your technology aspirations into reality.
            </p>
            <p className="text-white text-lg mb-4">
                My experience includes working with industry leaders in photonics and solid-state lighting, such as <a href='/clients' className={linksClass}>QuarkStar LLC</a> and <a href='/clients' className={linksClass}>Gaia Photonics Ltd</a>.
            </p>
        </section>
    );
}

export default Bio;
