// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { CameraIcon } from '@heroicons/react/16/solid';
import './App.css';

// Page components
import Bio from './pages/Bio';
import Clients from './pages/Clients';
import Projects from './pages/Projects';
import Story from './pages/Story';
import Consulting from './pages/Consulting';
import Resume from './pages/Resume';
import Contact from './pages/Contact';
import Photography from './pages/Photography'; // Import the Photography component
import LuminaireInstallation from './pages/articles/mfah-luminaire-installation';
import OpticsSylvania from './pages/articles/optics-of-sylvania-lighting';
import EdgeXTech from './pages/articles/quarkstar-edge-x-technology';
import UnderConstruction from './pages/UnderConstruction';


// Functional components
function MobileImage() {
  return (
    <div
      className="md:hidden h-96 mb-4"
      style={{
        backgroundImage: `url(${require('./images/photos/ronnie-johnson-portrait-bw-c.webp')})`,
        backgroundPosition: 'center top',
        backgroundSize: 'cover',
      }}
    ></div>
  );
}

function Header() {
  return (
    <header className="bg-black text-white p-4">
      <div className="m-0 md:m-12 md:mx-12 mb-0">
        <div className="flex flex-col items-start">
          <h1 className="text-5xl text-white font-black my-4">Ronnie Johnson.</h1>
          <h2 className="text-xl text-white mt-4">
            {/* Photonics Engineer / Data Scientist */}
            Engineer / Data Scientist
          </h2>
        </div>
        <NavBar />
      </div>
    </header>
  );
}

// function NavItem({ href, children }) {
//   return (
//     <li className="xl:mb-0 xl:mr-4">
//       <Link to={href} className="block py-2 px-0 hover:text-gray-400">
//         {children}
//       </Link>
//     </li>
//   );
// }

function NavItem({ href, children }) {
  const location = useLocation();
  const isActive = location.pathname === href;

  return (
    <li className="xl:mb-0 xl:mr-4">
      <Link
        to={href}
        className={`block py-2 px-0 hover:text-gray-400 ${isActive ? 'text-gray-400' : 'text-white'
          }`}
      >
        {children}
      </Link>
    </li>
  );
}

function NavBar() {
  return (
    <div>
      <div className="bg-white h-px w-full my-6"></div>
      <nav className="text-base bg-black text-white">
        <ul className="flex justify-between max-w-screen-lg mx-auto flex-col xl:flex-row items-center">
          <NavItem href="/bio">Bio</NavItem>
          <NavItem href="/clients">Clients</NavItem>
          <NavItem href="/projects">Projects</NavItem>
          <NavItem href="/consulting">Consulting</NavItem>
          <NavItem href="/resume">Résumé</NavItem>
          <NavItem href="/story">Story</NavItem>
          <NavItem href="/contact">Contact</NavItem>
        </ul>
      </nav>
      <div className="bg-white h-px w-full mt-6"></div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <div className="text-base bg-black flex flex-col min-h-screen relative">
        <div className="flex space-x-8 flex-1">
          <div className="w-full md:w-5/12 overflow-auto font-merriweather flex flex-col">
            <MobileImage />
            <Header />
            <main className="mx-0 md:my-0 md:mx-12 p-4 flex-1">
              <Routes>
                <Route path="/bio" element={<Bio />} />
                <Route path="/clients" element={<Clients />} />
                <Route path="/projects" element={<UnderConstruction />} />
                <Route path="/consulting" element={<Consulting />} />
                <Route path="/resume" element={<UnderConstruction />} />
                <Route path="/story" element={<UnderConstruction />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/articles/mfah-luminaire-installation" element={<LuminaireInstallation />} />
                <Route path="/articles/optics-of-sylvania-lighting" element={<OpticsSylvania />} />
                <Route path="/articles/quarkstar-edge-x-technology" element={<EdgeXTech />} />
                <Route path="/under_construction" element={<UnderConstruction />} />
                <Route path="/" element={<Bio />} /> {/* Default route */}
              </Routes>
              <Photography /> {/* Render Photography component */}
            </main>
            <Footer />
          </div>
          <div className="w-5/12 hidden md:block">
            <div className="fixed-image">
              {/* Add any background image or content here */}
            </div>
          </div>
        </div>
        {/* <Link to="/photography" className='hidden md:block'>
          <button
            className="fixed bottom-4 right-4 bg-black text-white p-4 rounded-full shadow-lg hover:bg-gray-900 transition-transform transform hover:scale-110"
            aria-label="Go to Photography Photography"
          >
            <CameraIcon className="w-6 h-6" />
          </button>
        </Link> */}
      </div>
    </Router>
  );
}

function Footer() {
  return (
    <footer className="m-4 md:m-12">
      <div className="bg-white h-px w-full mb-4"></div>
      <div className="flex flex-col items-end">
        <p className="text-right text-white mb-2">
          From Lasqueti Island, Canada 🇨🇦
        </p>
        <p className="text-right text-white">
          But you'll now find me on Vancouver Island, Canada 🇨🇦
        </p>
      </div>
    </footer>
  );
}

export default App;
