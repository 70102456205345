// src/pages/articles/optics-of-sylvania-lighting.js
import React from 'react';
import { linksClass, h1, h2, featureImageClass, imageClass } from '../../styles/siteClasses';
import { photoCreditClass, bulletPointClass } from '../../styles/articleClasses';
import '../../styles/article.css'; // Ensure the path is correct
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import BackButton from '../../components/BackButton'; // Import the BackButton component



const EdgeXTech = () => {

    return (
        <section className="prose mx-auto text-white">

            <BackButton />

            <h1 className={h1}>
                QuarkStar's Edge-X (waveguide) Technology
            </h1>

            <img
                src="/images/photos/upt-quarkstar-edge-x.webp"
                alt="QuarkStar Edge-X Q-Wall optics"
                className={featureImageClass}
            />

            <p className={photoCreditClass}>
                Close-up of Q-Wall optics using Edge-X technology. Photo – <a href='https://www.wittmann-group.com/en/report-upt-optik-wodak-gmbh' className={linksClass} > WITTMANN UPT-Optik Wodak GmbH</a>
            </p>

            <p className="mb-4">
                <span className="text-3xl">E</span>dge-X is an <a href="https://en.wikipedia.org/wiki/Waveguide#Optical_waveguides" className={linksClass}>optical refractive waveguide</a> technology designed to significantly improve the capture and delivery of light from a linear array of LEDs. This technology enables uniform light mixing between LEDs and redistributes the light into various distributions, depending on the final optical component, making it a versatile solution for numerous lighting applications.
            </p>

            <p className="mb-4">
                I joined the QuarkStar engineering team during the mid-development phase of Edge-X technology. Under the leadership of <a href="https://www.linkedin.com/in/ingo-speier-a6a93623/" className={linksClass}>Ingo Speier</a>, Head of Engineering, my primary responsibilities included the characterization and validation of this innovative technology. My work focused on data collection, analysis, and automation, enhancing and streamlining our research efforts. Additionally, I contributed to broader research and engineering tasks that further supported the technology's development and refinement.
            </p>

            <p className="mb-4">
                As part of the team, we developed a wide range of prototypes and received acclaim at various trade fairs, including at Light + Building and LightFair, for our innovations. These efforts ultimately led to successful projects such as the <a href="/articles/mfah-luminaire-installation" className={linksClass}>Luminaire Installation at the Nancy and Rich Kinder Building, The Museum of Fine Arts, Houston</a> and the <a href="/articles/optics-of-sylvania-lighting" className={linksClass}>Optics of Sylvania Lighting Concord Equinox</a>.
            </p>
            <p className="mb-4">
                Edge-X draws significant inspiration from <a href="https://en.wikipedia.org/wiki/Nonimaging_optics#Compound_parabolic_concentrator" className={linksClass}>Compact Parabolic Concentrators (CPC)</a>, a type of non-imaging optics, and the physical phenomenon of <a href="https://en.wikipedia.org/wiki/Total_internal_reflection" className={linksClass}>Total Internal Reflection (TIR)</a>, which are key to its unique optical performance.
            </p>

            <h1 className={h1}>
                Q-Wall — An Edge-X Success Story
            </h1>

            <img
                src="/images/photos/qwall-Full_100_800_6488-HDR-NoWire.jpg"
                alt="The standard Q-Wall model."
                className={featureImageClass}
            />

            <p className={photoCreditClass}>
                Base Q-Wall model. Photo – Ronnie Johnson
            </p>

            <p className="mb-4">
                QuarkStar's Q-Wall luminaire is a cutting-edge wall-washing fixture known for its remarkable performance and specifications. Despite its narrow optical exit aperture, measuring less than 2 cm (0.87 inches) wide, the Q-Wall can effectively illuminate walls up to 5 meters (15 feet) high with exceptional uniformity, achieving a 1:3 ratio. The fixture also features fully mixed colour tuning, allowing it to seamlessly adapt to the prevailing daylight environment. These advanced capabilities are made possible by QuarkStar’s proprietary Edge-X optical refractive waveguide technology. <span className="text-yellow-400 text-xl relative" style={{ top: '0.15em' }}>■</span>
            </p>

            <ul className="custom-list list-disc pl-5"> {/* Add padding to align the list */}
                <li className="mb-4 flex items-start">
                    <InformationCircleIcon className={bulletPointClass} />
                    <span className="flex-1">
                        The Q-Wall was prominently featured in the <a href="/articles/mfah-luminaire-installation" className={linksClass}>Luminaire Installation at the Nancy and Rich Kinder Building, The Museum of Fine Arts, Houston</a>
                    </span>
                </li>
            </ul>
            
            <h3 className={h1}>
                Press:
            </h3>
            
        </section>
    );
};

export default EdgeXTech;